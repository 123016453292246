import React from 'react';
import useForm from '../../../../hooks/UseForm';
import Input from '../Input/Input';
import Button from '../../../Utils/Button/Button';
import './Form.scss';

const Form = () => {
  const name = useForm();
  const phone = useForm('number');
  const email = useForm('email');
  const textarea = useForm('');

  async function handleSubmit(event) {
    event.preventDefault();

    const receiverData = {
      name: name.value || '',
      phone: phone.value || '',
      email: email.value || '',
      message: textarea.value || '',
    };

    if (
      name.validate() &&
      phone.validate() &&
      email.validate() &&
      textarea.validate()
    ) {
      const settings = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(receiverData),
      };

      try {
        const fetchResponse = await fetch(
          `https://dashboard.velloware.com/api/receiver`,
          settings,
        );
        const data = await fetchResponse.json();
        console.log(data);
      } catch (e) {
        return e;
      }
    }
  }

  return (
    <section className="form" id="message">
      <form onSubmit={handleSubmit}>
        <Input
          label="Nome"
          type="text"
          name="name"
          {...name}
          placeholder="Seu nome aqui"
        />
        <div className="form__two_elements">
          <Input
            label="Telefone"
            type="text"
            name="phone"
            {...phone}
            placeholder="(99) 99999-9999"
          />
          <Input
            label="Email"
            type="text"
            name="email"
            {...email}
            placeholder="email@email.com"
          />
        </div>

        <Input
          label="Mensagem"
          type="text"
          name="textarea"
          textarea={true}
          placeholder="Conte-nos o que deseja aqui!"
          {...textarea}
        />
        <Button>Cadastrar</Button>
      </form>
    </section>
  );
};

export default Form;
