import React from 'react';
import './Input.scss';

const Input = ({
  label,
  type,
  name,
  value,
  onChange,
  error,
  onBlur,
  placeholder,
  textarea,
}) => {
  return (
    <div className="wrapper">
      <label htmlFor={name} className="label">
        {label}
      </label>
      {textarea ? (
        <textarea
          id={name}
          name={name}
          className="input"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
        ></textarea>
      ) : (
        <input
          id={name}
          name={name}
          className="input"
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
        />
      )}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default Input;
