import Team from '../api/db/Team.json';

const getTeam = (id = 0) => {
  if (id > 0) {
    for (let i = 0; i < Team.team.length; i += 1) {
      if (Team.team[i].id === id) {
        return Team.team[i];
      }
    }
  }

  return Team.team;
};

export default getTeam;
