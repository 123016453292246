import React from 'react';
import './HowItWorks.scss';

const HowItWorks = () => {
  return (
    <div className="howitworks">
      <div className="howitworks__container">
        <div className="howitworks__title">
          <h3>Como funciona?</h3>
          <h1>
            <span className="howitworks__text__decorator">Processo</span> de
            criação
          </h1>
        </div>
        <div className="howitworks__list">
          <h3>Etapas</h3>
          <ul>
            <li>Entre em contato</li>
            <li>Definição de requisitos</li>
            <li>Aprovação de Layout</li>
            <li>Execução do projeto</li>
            <li>Entrega</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
