import React from 'react';
import Card from './Card/Card';
import getTeam from '../../../hooks/useTeam';
import './Team.scss';

const Team = () => {
  const teamList = getTeam();

  return (
    <div className="team">
      <div className="team__container">
        <div>
          {teamList.map((item, index) => {
            return (
              <Card
                key={index}
                name={item.name}
                img={item.img}
                alt={item.name}
                link={item.link}
                text={item.description}
              />
            );
          })}
        </div>
        <div className="team__text__decorator">
          <h1>Conheça o nosso time</h1>
        </div>
      </div>
    </div>
  );
};

export default Team;
