import React from 'react';
import Image from '../../../Utils/Image/Image';
import PropTypes from 'prop-types';
import './Card.scss';

const Card = ({ img, alt, name, text, link }) => {
  return (
    <div className="team__card team__card__decorator">
      <div className="team__image">
        <a href={link} target="__blank">
          <div className="flip-card">
            <div className="inner">
              <div className="front">
                <Image src={img} alt={alt} />
              </div>
              <div className="back">
                <p>Acessar Perfil</p>
              </div>
            </div>
          </div>
        </a>
      </div>
      <h3>{name}</h3>
      <p>{text}</p>
    </div>
  );
};

export default Card;

Card.propType = {
  img: PropTypes.string,
  alt: PropTypes.string,
  name: PropTypes.name,
  text: PropTypes.string,
  link: PropTypes.string,
};
