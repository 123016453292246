import React from 'react';
import './Image.scss';

const Image = ({ radiusBorder, alt, ...props }) => {
  const [skeleton, setSkeleton] = React.useState(true);

  function handleLoad({ target }) {
    setSkeleton(false);
    target.style.opacity = 1;
  }
  return (
    <div className="wrapper">
      {skeleton && (
        <div
          className="skeleton"
          style={{ borderRadius: `${radiusBorder || ''}` }}
        ></div>
      )}
      <img onLoad={handleLoad} className="img" alt={alt} {...props} />
    </div>
  );
};

export default Image;
