import React from 'react';
import Logo from '../../assets/logo.svg';
import useMedia from '../../hooks/useMedia';
import useMessage from '../../hooks/useMessage';
import Button from '../Utils/Button/Button';
import './Header.scss';

const Header = () => {
  const mobile = useMedia('(max-width: 53rem)');
  const [mobileMenu, setMobileMenu] = React.useState(false);

  React.useEffect(() => {
    setMobileMenu(false);
  }, []);

  return (
    <header>
      <nav className="nav container" data-menu="suave">
        <a href="#">
          <img className="logo" src={Logo} alt="Logo da empresa Velloware" />
        </a>

        <ul
          className={`${'navElements'} ${mobile && 'mobileNav'} ${
            mobileMenu && 'mobileNav--active'
          } `}
        >
          <li>
            <a href="#works">Sites</a>
          </li>
          <li>
            <a href="#about">Sobre</a>
          </li>
          <li>
            <a href="#contact">Contato</a>
          </li>
          <li>
            <Button onClick={useMessage}>Faça um orçamento</Button>
          </li>
        </ul>

        {mobile && (
          <div
            aria-label="Menu"
            className={`mobileButton hamburger__menu ${
              mobileMenu && 'mobileButton-active'
            }`}
            onClick={() => setMobileMenu(!mobileMenu)}
          >
            <div
              className={`bar__top ${mobileMenu && 'bar__top--active'}`}
            ></div>
            <div
              className={`bar__middle ${mobileMenu && 'bar__middle--active'}`}
            ></div>
            <div
              className={`bar__bottom ${mobileMenu && 'bar__bottom--active'}`}
            ></div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
