import React from 'react';
import './About.scss';

const About = () => {
  return (
    <div className="about" id="about">
      <section className="about__container">
        <div className="about__decoration">
          <h1>VELLOWARE</h1>
        </div>
        <div className="about__text">
          <p>
            Desenvolvemos aplicações web e aplicativos mobile para seu negócio,
            realizamos o planejamento, analise de requisitos e a prototipagem do
            seu sistema, sempre utilizando os melhores conceitos para solucionar
            seu problema e dar mais valor ao seu negocio.
          </p>
          <ul>
            <li>Aplicativos Mobile</li>
            <li>Web Apps</li>
            <li>Bots para automação</li>
            <li>Landing Pages</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default About;
