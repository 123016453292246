import Jobs from '../api/db/Jobs.json';

const getJobs = (id = 0) => {
  if (id > 0) {
    for (let i = 0; i < Jobs.jobs.length; i += 1) {
      if (Jobs.jobs[i].id === id) {
        return Jobs.jobs[i];
      }
    }
  }

  return Jobs.jobs;
};

export default getJobs;
