import React from 'react';
import terminal from '../../../assets/terminal.svg';
import speed from '../../../assets/speed.svg';
import team from '../../../assets/team.svg';
import like from '../../../assets/like.svg';
import './Benefits.scss';

const Benefits = () => {
  return (
    <section className="benefits container">
      <h1>
        Vantagens de trabalhar{' '}
        <span className="benefits__text__decorator">conosco</span>
      </h1>
      <div>
        <ul>
          <li>
            <div>
              <img src={terminal} alt="Icone terminal" />
              <h3>Tecnologias Atuais</h3>
              <p>
                Utilizamos da tecnologias mais atuais do mercado para que o seu
                projeto seja mais seguro e eficiente
              </p>
            </div>
          </li>
          <li>
            <div>
              <img src={speed} alt="Icone velocimetro" />
              <h3>Rapidez</h3>
              <p>
                Temos uma equipe pronta para lhe atender para que sua demanda
                seja atendida o mais rápido possível
              </p>
            </div>
          </li>
          <li>
            <div>
              <img src={team} alt="Icone equipe" />
              <h3>Equipe Qualificada</h3>
              <p>
                Temos uma equipe de profissionais extremamente qualificada e
                treinada para lhe atender.
              </p>
            </div>
          </li>
          <li>
            <div>
              <img src={like} alt="Icone curtida" />
              <h3>Credibilidade</h3>
              <p>Somos uma equipe de profissionais sérios e confiáveis.</p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Benefits;
