import React from 'react';
import Button from '../../Utils/Button/Button';
import './Banner.scss';
import useMessage from '../../../hooks/useMessage';

const Banner = () => {
  return (
    <>
      <section className="banner container" id="home" data-menu="suave">
        <div>
          <h1>
            <span className="banner__text__decorator">Vamos</span> criar um{' '}
            <span className="banner__border__decorator">site</span> para a sua{' '}
            <span className="banner__border__decorator">ideia</span>
          </h1>
          <p>
            Coloque seu sonho em prática com a <span> VELLOWARE</span>
          </p>
          <Button onClick={useMessage}>Fale Conosco</Button>
        </div>
        <div className="banner__image"></div>
      </section>
      <a className="banner__arrow" href="#about"></a>
    </>
  );
};

export default Banner;
