import React, { useEffect } from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Sections from './components/Sections/Sections';
import ScrollSuave from './components/Utils/ScrollSuave';
import './App.scss';

const App = () => {
  useEffect(() => {
    const scrollSuave = new ScrollSuave('[data-menu="suave"] a[href^="#"]');
    scrollSuave.init();
  }, []);

  return (
    <div className="animeLeft">
      <Header />
      <Sections />
      <Footer />
    </div>
  );
};

export default App;
