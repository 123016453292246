import React from 'react';
import Form from './Form/Form';
import phone from '../../../assets/phone.svg';
import mail from '../../../assets/mail.svg';
import location from '../../../assets/location.svg';
import instagram from '../../../assets/instagram.svg';
import facebook from '../../../assets/facebook.svg';
import whatsapp from '../../../assets/whatsapp.svg';
import useMessage from '../../../hooks/useMessage';
import './Contact.scss';

const Contact = () => {
  return (
    <>
      <div>
        <div className="contact__decorator"></div>
        <div className="contact__decorator"></div>
      </div>

      <div className="contact container" id="contact">
        <div className="contact__card">
          <div className="contact__data">
            <h2>
              <span className="contact__text__decorator">Fale</span> Conosco
            </h2>
            <p>
              Entre em contato pelo telefone ou email abaixo, ou então se
              preferir, preencha o formulário que entramos em contato.
            </p>

            <ul>
              <li>
                <img src={phone} alt="Telefone" /> <p>+55 63 99107-2312</p>
              </li>
              <li>
                <img src={mail} alt="E-mail" /> <p>velloware@velloware.com</p>
              </li>
              <li>
                <img src={location} alt="Localicação" />{' '}
                <p>Gurupi, Tocantins</p>
              </li>
            </ul>
            <div className="contact__socialmedia">
              <a
                href="https://www.facebook.com/profile.php?id=100072678195643&mibextid=LQQJ4d"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="Facebook" />
              </a>
              <a
                href="https://instagram.com/velloware?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="Instagram" />
              </a>
              <a onClick={useMessage}>
                <img src={whatsapp} alt="WhatsApp" />
              </a>
            </div>
          </div>
          <div>
            <Form id="message" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
