import React, { useEffect } from 'react';
import Image from '../../Utils/Image/Image';
import './Carrousel.scss';
import SlideNav from './Slide/slideNav';
import getJobs from '../../../hooks/useJobs';

const projectsList = getJobs();

const Carrousel = () => {
  useEffect(() => {
    const slide = new SlideNav(
      `[data-slide="slide"]`,
      `[data-slide="slide_wrapper"]`,
    );
    slide.init();
  }, []);

  return (
    <div className="carroussel" id="works">
      <h1>Conheça nossos últimos projetos</h1>
      <div data-slide="slide_wrapper">
        <ul data-slide="slide">
          {projectsList.map((item, index) => {
            return (
              <li key={index}>
                <Image src={item.img} alt={item.title} />
                <a href={item.link} target="_blank" rel="noreferrer">
                  <h2>
                    {item.title} - {item.date}
                  </h2>
                </a>
                <p>
                  {item.description}{' '}
                  {item.link && (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={item.link}
                      style={{ textDecoration: 'underline', color: '#b52fe5' }}
                    >
                      {' '}
                      Veja online
                    </a>
                  )}{' '}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Carrousel;
