import React from 'react';
import Carrousel from './Carrousel/Carrousel';
import './Sections.scss';
import About from './About/About';
import HowItWorks from './HowItWorks/HowItWorks';
import Benefits from './Benefits/Benefits';
import Team from './Team/Team';
import Banner from './Banner/Banner';
import Contact from './Contact/Contact';

const Sections = () => {
  return (
    <>
      <Banner />
      <About />
      <Carrousel />
      <HowItWorks />
      <Benefits />
      <Team />
      <Contact />
    </>
  );
};

export default Sections;
